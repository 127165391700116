/*eslint-disable*/
import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';

//import FluidImage from '../../FluidImage';

function CustomNavbar({data, ...props}) {

  const onclick = () => {
    document.documentElement.classList.remove('is-nav-active')
  }

  return (

    <nav className="navbar is-fixed-top is-transparent is-family-secondary" role="navigation" aria-label="main navigation" id="navbar">
  
      <div className="navbar-brand">
        
        <Link to="/" className={["navbar-item", "navbar-logo", props.location.pathname==="/"?"is-active":""].join(" ")}>
          <span className="is-image-replaced">Network Ventures</span>
        </Link>

        <a className="navbar-burger burger" role="button" data-target="navMenu" aria-label="menu" aria-expanded="false">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>

      </div>

      <div className="navbar-menu" id="navMenu">
        
        <div className="navbar-start">
        </div>

        <div className="navbar-end">
          
          <Link to="/" onClick={onclick} className={["navbar-item", props.location.pathname==="/"?"is-active":""].join(" ")}>
            Home
          </Link>
          <Link to="/about" onClick={onclick} className={["navbar-item", props.location.pathname==="/about"?"is-active":""].join(" ")}>
            About
          </Link>
          <Link to="/references" onClick={onclick} className={["navbar-item", props.location.pathname==="/references"?"is-active":""].join(" ")}>
            Reference
          </Link>
          <Link to="/members" onClick={onclick} className={["navbar-item", props.location.pathname==="/members"?"is-active":""].join(" ")}>
            Member
          </Link>
          <Link to="/partners" onClick={onclick} className={["navbar-item", props.location.pathname==="/partners"?"is-active":""].join(" ")}>
            Partner
          </Link>
          <Link to="/submit" onClick={onclick} className={["navbar-item", props.location.pathname==="/submit"?"is-active":""].join(" ")}>
            Submit
          </Link>
          <Link to="/contact" onClick={onclick} className={["navbar-item", props.location.pathname==="/contact"?"is-active":""].join(" ")}>
            Contact
          </Link>
          {/* <Link to="/termsofuse" className={["navbar-item", " navbar-item-marginal", props.location.pathname==="/termsofuse"?"is-active":""].join(" ")}>
            Terms of Use
          </Link> */}
          <Link to="/codeofconduct" onClick={onclick} className={["navbar-item", " navbar-item-marginal", props.location.pathname==="/codeofconduct"?"is-active":""].join(" ")}>
            Code of Conduct
          </Link>
          <Link to="/imprint" onClick={onclick} className={["navbar-item", " navbar-item-marginal", props.location.pathname==="/imprint"?"is-active":""].join(" ")}>
            Imprint
          </Link>
        </div>

      </div>

    </nav>

  );
}

const CustuomNavbarWrapper = props => (
  <StaticQuery
    query={graphql`
      query {
        allFiles: allFile(filter: {relativeDirectory: {eq: "Teams"}, relativePath: {regex: "/^((?!DRAFT).)*$/"}, extension: { eq: "yaml" }}) {
          edges {
            node {
              relativePath
            }
          }
        }
      }
    `}
    render={data => <CustomNavbar data={data} {...props} />}
  />
);
export default CustuomNavbarWrapper;
