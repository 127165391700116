import React from 'react';

import '../../assets/css/font.css';
import '../../assets/css/main.css';
import {Helmet} from "react-helmet";


const WithUIKit = WrappedComponent => {


  return props => {
    return (
      <>
        <Helmet>
          <script>
            {`
              let MTIProjectId='3aaed528-123d-4b56-af79-5dae7f15e5ac';
              (function() {
                let mtiTracking = document.createElement('script');
                mtiTracking.type='text/javascript';
                mtiTracking.async='true';
                mtiTracking.src='/js/mtiFontTrackingCode.js';
                (document.getElementsByTagName('head')[0]||document.getElementsByTagName('body')[0]).appendChild( mtiTracking ); 
              })();
            `}

          </script>

          <script type="module" src="https://unpkg.com/ionicons@5.0.0/dist/ionicons/ionicons.esm.js"></script>
          <script nomodule="" src="https://unpkg.com/ionicons@5.0.0/dist/ionicons/ionicons.js"></script>
        </Helmet>
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default WithUIKit;
