import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const FluidImage = (props) => {
  const {src} = props;

// sizes: "(max-width: 600px) 600px, (max-width: 900px) 900px, (max-width: 1200px) 1200px, (max-width: 1800px) 1800px, (max-width: 2400px) 2400px"

  return (
    <StaticQuery query={graphql`{
  allFile(filter: {extension: {regex: "/jpeg|jpg|png|gif/"}}) {
    edges {
      node {
        extension
        relativePath
        childImageSharp {
          gatsbyImageData(
            breakpoints: [300, 600, 900, 1200, 1800, 2400]
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }
  }
}

`} render={images => {
        //console.log("FluidImage data logCard img", props, images);
        let data
        //try{
          data = images.allFile.edges.find(({ node }) => src ? node.relativePath === src.replace('/static/images/', '') : node);
        //} catch {
        //  return <img src="images/content/Blog/Blog1_0.jpeg"   />;
        //}
        //console.log("xxx", data);
        
        if (data) {
          return (
            <GatsbyImage
              image={data.node.childImageSharp.gatsbyImageData}
              {...props }
              alt="image"
              loading="lazy"
              />
          );
        }
        return <p style={{color: "red", fontWeight: 800}}>Error {src}</p>;
      }
    }/>
  );
};

export default FluidImage;
