import React from 'react';
import {Helmet} from "react-helmet";
//import * as Constants from '../constants';

import WithUIKit from '../components/Custom/WithUIKit';
import CustomNavbar from '../components/Custom/Navigation/CustomNavbar';
import Footer from '../components/Custom/Navigation/Footer';
import {init} from '../assets/js/main.js';

const DefaultLayout = (props) => {


  React.useEffect(() => {
    //console.log("DefaultLayout xxx useEffect");
    const html = document.documentElement;
    html.classList.add('has-navbar-fixed-top');
    html.setAttribute('id', 'html');
    init();
  }, []);

  //console.log("DefaultLayout", props);
  return (
    <>
      <Helmet>

        <meta charset="utf-8" />
        <title>Network Ventures</title>

        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color"                            content="#D64933" />
        <meta name="msapplication-navbutton-color"          content="#D64933" />
        <meta name="apple-mobile-web-app-status-bar-style"  content="#D64933" />

        <link rel="canonical"				href="http://www.ntwk.ventures" />
        <meta name="generator"				content="Good ol&apos; handmade code" />
        <meta name="page-topic" 			content="Network Ventures promotes innovative idea providers to put their business on the road. Together, let the rockets fly." />
        <meta name="page-type"				content="Registered Cooperative's Frontwindow" />
        <meta name="description" 			content="We help innovative idea providers to put their business on the road. Together, let the rockets fly." />
        <meta name="keywords" 				content="österreich austria tirol tyrol innsbruck" />
        <meta name="audience"				content="companies organisations startups visionaries members partners vcs" />
        <meta name="author" 				content="Network Ventures" />
        <meta name="publisher" 				content="Network Ventures" />
        <meta name="copyright" 				content="Network Ventures" />

        <meta name="city" 					content="Innsbruck" />
        <meta name="country" 				content="Austria" />
        <meta name="state" 					content="Tyrol" />
        <meta name="zipcode" 				content="6020" />
        <meta name="geo.position" 	        content="47.2601210;11.3956200" />
        <meta name="geo.placename"          content="Innsbruck" />
        <meta name="geo.region"             content="AT-Tirol" />
        <meta name="icbm" 					content="47.2601210,11.3956200" />

      </Helmet>
      <CustomNavbar location={props.location}/>

      <div className="srnav is-sr-only">
        <a href="#page" title="Content">Go to content</a>
        <a href="#navMenu" title="Filter">Go to menu</a>
      </div>

      <div className="frame">
        <div className="page" id="page">
        {props.children}
        </div>
        <Footer location={props.location}/>
      </div>
    </>
  );
};
export default WithUIKit(DefaultLayout);
