//  TRANSPORTER
// 	Creating Your Web
// 	www.transporter.at



/*
https://blog.garstasio.com/you-dont-need-jquery/selectors/
id: 						document.getElementById('myElement');
class: 						document.getElementsByClassName('myElement');
tag: 						document.querySelectorAll('div');
attribute: 					document.querySelectorAll('[data-foo-bar="someval"]');
pseude-class: 				document.querySelectorAll('#myForm :invalid');
children: 					document.getElementById('myParent').children;
descendants:				document.querySelectorAll('#myParent A');
excluding elements: 		document.querySelectorAll('DIV:not(.ignore)');
multiple selectors:			document.querySelectorAll('DIV, A, SCRIPT');
*/

export const init = () => {



    //////////////////////////////////////
    //  variables
    //////////////////////////////////////

    const myhtml = document.documentElement;
    const mybody = document.getElementById('body');

    const navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    const heroHome = document.getElementById('hero-home');

    //console.log("main.js xxx", heroHome);




    //////////////////////////////////////
    //  functions
    //////////////////////////////////////

    function scroll_position() {
        return(window.pageYOffset);
    }
    function window_height() {
        return(window.innerHeight);
    }
    function page_height() {
        return(document.body.offsetHeight);
    }
    function hero_height() {
        return(heroHome.offsetHeight);
    }





    //////////////////////////////////////
    //  scroll
    //////////////////////////////////////

    function hero_scroller() {
        //console.log("hero_scrollerxxx", heroHome);
        if(typeof(heroHome) != 'undefined' && heroHome != null) {
            if (scroll_position() > (hero_height() - 200)) {
                myhtml.classList.add('is-scrolled');
            } else {
                myhtml.classList.remove('is-scrolled');
            }
        }
    }

    function top_scroller() {
        if (page_height() > window_height()) {
            myhtml.classList.add('has-scroller');
        } else {
            myhtml.classList.remove('has-scroller');
        }
    }

    hero_scroller();
    top_scroller();

    window.onscroll = function(ev) {
        hero_scroller();
    };

    window.onresize = function(ev) {
        hero_scroller();
        top_scroller();
    };





    //////////////////////////////////////
    //  navbar
    //////////////////////////////////////

    if (navbarBurgers.length > 0) {

        // Add a click event on each of them
        navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');

                //shift menu (back) to top scroll position
                if ($target.classList.contains('is-active')) {
                    $target.scrollTop = 0;
                }
                
                //toggle "is-clipped" on html
                myhtml.classList.toggle('is-nav-active');

            });
        });
    }

}


