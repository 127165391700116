import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import FluidImage from '../../FluidImage';
//import NewsletterSection from '../Sections/NewsletterSection';

const Footer = ({...props}) => {

  return (
    <>
      <footer className="footer has-text-centered is-letter-spacing">
    
        <div className="footer-scroll">
            <a href="#html">
                <span className="icon icon-arrow-up"></span>
                <span className="is-sr-only">Top</span>
            </a>
        </div>
        
        <div className="footer-navigation">
            
            <div className="columns is-multiline is-centered is-uppercase">
                <div className="column is-narrow">
                  <Link to="/" className={[props.location.pathname==="/"?"is-active":""].join(" ")}>Home</Link>
                </div>
                <div className="column is-narrow">
                  <Link to="/about" className={[props.location.pathname==="/about"?"is-active":""].join(" ")}>About</Link>
                </div>
                <div className="column is-narrow">
                  <Link to="/references" className={[props.location.pathname==="/references"?"is-active":""].join(" ")}>Reference</Link>
                </div>
                <div className="column is-narrow">
                  <Link to="/members" className={[props.location.pathname==="/members"?"is-active":""].join(" ")}>Member</Link>
                </div>
                <div className="column is-narrow">
                  <Link to="/partners" className={[props.location.pathname==="/partners"?"is-active":""].join(" ")}>Partner</Link>
                </div>
                <div className="column is-narrow">
                  <Link to="/submit" className={[props.location.pathname==="/submit"?"is-active":""].join(" ")}>Submit</Link>
                </div>
                <div className="column is-narrow">
                  <Link to="/contact" className={[props.location.pathname==="/contact"?"is-active":""].join(" ")}>Contact</Link>
                </div>
            </div>

            <div className="columns is-multiline is-centered">
              {/* <div className="column is-narrow">
                  <Link to="/termsofuse" className={[props.location.pathname==="/termsofuse"?"is-active":""].join(" ")}>Terms of Use</Link>
                </div> */}
                <div className="column is-narrow">
                  <Link to="/codeofconduct" className={[props.location.pathname==="/codeofconduct"?"is-active":""].join(" ")}>Code of Conduct</Link>
                </div>
                <div className="column is-narrow">
                  <Link to="/imprint" className={[props.location.pathname==="/imprint"?"is-active":""].join(" ")}>Imprint</Link>
                </div>
            </div>

        </div>


        {/* <div className="footer-sm">

          <div className="columns is-multiline is-centered">
            <div className="column is-narrow">
              <a href="#" className="sm-linkedin" title="LinkedIn"><ion-icon name="logo-linkedin" size="large"></ion-icon></a>
            </div>
            <div className="column is-narrow">
              <a href="#" className="sm-instagram" title="Instagram"><ion-icon name="logo-instagram" size="large"></ion-icon></a>
            </div>
            <div className="column is-narrow">
              <a href="#" className="sm-facebook" title="Facebook"><ion-icon name="logo-facebook" size="large"></ion-icon></a>
            </div>
            <div className="column is-narrow">
              <a href="#" className="sm-twitter" title="Twitter"><ion-icon name="logo-twitter" size="large"></ion-icon></a>
            </div>
          </div>

        </div> */}

        <div className="footer-copyright">
            <p>
                {new Date().getFullYear()} Network&nbsp;Ventures
            </p>
        </div>

    </footer>
    </>
  );
};

export default Footer;
